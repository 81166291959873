<template>
  <v-footer
    absolute
    class="my-3 mx-auto"
    color="transparent"
    height="auto"
    max-width="95%"
  >
    <v-row justify="center">
      &copy;{{ new Date().getFullYear() }} - <strong>StonckNow</strong>

      <v-spacer />

      <v-col
        class="hidden-md-and-up"
        cols="12"
      />

      <v-btn
        v-for="([href, text], i) in socials"
        :key="`social-${i}`"
        :href="href"
        class="px-0 mx-2"
        color="blue darken-1"
        dark
        min-width="48"
        rel="noopener"
        target="_blank"
      >
        <v-icon
          size="18"
          v-text="text"
        />
      </v-btn>
    </v-row>
  </v-footer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'CoreFooter',

    data: () => ({
      socials: [
        ['https://www.youtube.com/c/Stoncknow', 'mdi-youtube'],
        ['https://twitter.com/stoncknow', 'mdi-twitter'],
        ['https://www.instagram.com/stoncknow/', 'mdi-instagram'],
        ['mailto:yohan@stoncknow.com', 'mdi-mail'],
      ],
    }),

    computed: {
      ...mapState('app', ['links']),
      categories () {
        return []
      },
    },
  }
</script>
